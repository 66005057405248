import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { EversoulCharacter } from '../../../modules/es/common/components/eversoul-character';

const EsGuidesGaia: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul/">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Gaia - raid guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/eversoul/categories/category_gaia.png"
            alt="Gaia - raid guide"
          />
        </div>
        <div className="page-details">
          <h1>Gaia - raid guide</h1>
          <h2>Struggling with the Gaia Raid? This guide will help you!</h2>
          <p>
            Last updated: <strong>14/03/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Gaia Mechanics" />
        <p>
          Gaia has some very important mechanics to pay attention to, both to
          survive the fight and to deal optimal damage. When building teams, it
          will be important to incorporate units that fill specific roles which
          the mechanics require. Not to worry, there will be suggested
          characters and team composition guidelines provided!
        </p>
        <p>
          Gaia’s rotation doesn’t deviate, as she can’t be slowed and will cast
          her main skill regardless of how much mana she has. This makes
          remembering the rotation and when certain mechanics will appear fairly
          easy to follow:
        </p>
        <p>
          Normal &gt; Roar &gt; Karma Shackles &gt; Normal &gt; Thorn Waves &gt;
          Obliterate
        </p>
        <h5>Karma Shackles (thorn cage):</h5>
        <StaticImage
          src="../../../images/eversoul/gaia/gaia_01.webp"
          alt="Gaia"
        />
        <p>
          Possibly the most important mechanic to deal with is Gaia’s Karma
          Shackles, as if you don’t prepare a target for it, it could easily
          kill one of your characters. This attack will target the unit with the
          highest HP on your team, regardless of where they’re positioned on the
          field. It will stun and deal damage over time to that unit for 8
          seconds, which is calculated using the unit’s own Atk stat. Based on
          this, the ideal candidate to soak this attack for your team is a unit
          with high HP and low Atk, so that they don’t end up killing
          themselves.{' '}
        </p>
        <StaticImage
          src="../../../images/eversoul/gaia/gaia_02.webp"
          alt="Gaia"
        />
        <p>
          After the 8 seconds are up, if the unit survives the attack (which
          they definitely should, otherwise you’re doing something wrong) your
          whole team acquires the Purified Soul buff, which raises their Atk by
          a huge 50%!{' '}
        </p>
        <StaticImage
          src="../../../images/eversoul/gaia/gaia_03.webp"
          alt="Gaia"
        />
        <p>
          This of course makes it ideal to hold any skills that are queued up
          until after the buff is applied. More on this later.{' '}
        </p>
        <h5>Thorn Waves</h5>
        <p>
          The next mechanic is one that you’ll have to prepare for using your
          team formation rather than anything you do within the fight itself
          (except maybe if you wish to cleanse the stun, though it isn’t really
          worth it). The wave will only stun certain positions in the team
          formation, making it easy to put a specific unit in that slot to eat
          the stun for you so that important characters aren’t getting hit. It
          also allows you to limit the amount of units that get stunned to 1
          rather than 3 if you use your formations correctly.
        </p>
        <StaticImage
          src="../../../images/eversoul/gaia/gaia_04.webp"
          alt="Gaia"
        />
        <ul>
          <li>
            The positions highlighted in pink are where the units will be
            stunned by the attack.{' '}
          </li>
          <li>
            As the Basic and Defense formations only have one slot being hit by
            the stun, they are the most ideal formations to use for Gaia.{' '}
          </li>
          <li>
            Avoid using Ranged or Assault formations, as you will end up having
            3 units stunned.
          </li>
        </ul>
        <p>
          As for which unit(s) you choose to have hit by the stun, it’s
          typically best for your tank (the same one being stunned by the
          shackles) to take the hit, however if you’re using the Basic
          formation, it may be best to position a support or sub-DPS unit there
          so that your main DPS isn’t losing DPS time. Just make sure you don’t
          have anyone important there!
        </p>
        <h5>Obliterate (charged orb slam)</h5>
        <p>
          Outside of having a unit to tank Karma Shackles, Obliterate is
          basically what teambuilding for Gaia is all about. First and foremost,
          mana control is (once again) hugely important. As her mana increases,
          Obliterate also increases in power and additional effects. Ideally,
          Gaia should never have more than 25% mana when casting this skill. Not
          only does this prevent the Obliterate skill from stunning your whole
          team for 5 seconds, (or worse, killing your team as the damage ramps
          up significantly the more mana Gaia has) but it will put Gaia in a
          Mana deficiency state after casting the skill.{' '}
        </p>
        <StaticImage
          src="../../../images/eversoul/gaia/gaia_05.webp"
          alt="Gaia"
        />
        <p>
          This debuff stacks up to 3 times, increasing your team’s damage to
          Gaia by 36% after the boss’ third rotation, assuming you’ve properly
          managed Gaia’s mana.{' '}
        </p>
        <p>
          There is also another important DPS tactic to note here, as previously
          hinted at in the Karma Shackles section. The 50% Atk buff received
          from Karma Shackles is timed to almost perfectly run out right after
          Gaia’s rotation ends, which is after Obliterate. However, it is
          possible to optimize your damage by waiting until just after Gaia
          casts Obliterate, but before the Purified Soul Atk buff runs out. This
          is only important prior to having full stacks of Mana Deficiency on
          Gaia, but every little bit helps!
        </p>
        <p>
          The window for attack is small (approximately 2 seconds), but not too
          hard to hit if you know what to look for. When Gaia begins the
          Obliterate attack, she will charge up an orb of energy:
        </p>
        <StaticImage
          src="../../../images/eversoul/gaia/gaia_06.webp"
          alt="Gaia"
        />
        <p>
          Then when Gaia unleashes the attack (hitting the ground with the first
          fist) is when the debuff is applied. At this point, you should have 2
          seconds left on the Purified Soul buff, just enough time to fire off
          your hardest hitting attacks/ultimate:
        </p>
        <StaticImage
          src="../../../images/eversoul/gaia/gaia_07.webp"
          alt="Gaia"
        />
        <p>
          Of course, if you failed to keep Gaia’s mana down, this won’t be
          relevant as the debuff won’t be gained, and your team will be stunned
          by the attack. After gaining the third stack of Mana Deficiency, this
          timing won’t be relevant anymore as the damage won’t be increased
          beyond 3 stacks, so using your skills anytime during Purified Soul is
          fine.
        </p>
        <h5>Burn</h5>
        <p>
          An interesting mechanic not tied to Gaia’s skill rotation is her
          weakness to Burn. It only affects your Physical damage, so placing a
          character with a burn on a team that mainly deals Magic damage won’t
          do much, if anything. However it is worth mentioning for
          Physical-dominant (Str and Dex) teams.
        </p>
        <StaticImage
          src="../../../images/eversoul/gaia/gaia_08.webp"
          alt="Gaia"
        />
        <h5>Earth’s Roar</h5>
        <p>
          Gaia’s second attack, Earth’s Roar, isn’t particularly important in
          the grand scheme of things, but it does apply 15% Physical and Magic
          resistances to Gaia, so unleashing attacks while the buff is up will
          reduce their damage. It’s 12 seconds long, so waiting for it to wear
          off will probably result in lower DPS overall, unless your skill comes
          up when the debuff is nearing its end.
        </p>
        <SectionHeader title="Useful Characters" />
        <p>
          As the game progresses, teambuilding for guild raids has become easier
          as more characters become available. This allows for more options as
          replacements for units you may be missing/haven’t built yet while
          still allowing for a decent score. Here are some top picks to deal
          with Gaia’s mechanics and deal great DPS.{' '}
        </p>
        <h5>Mana Control</h5>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="eve" enablePopover />
          <EversoulCharacter mode="icon" slug="aira" enablePopover />
          <EversoulCharacter mode="icon" slug="ayame" enablePopover />
          <EversoulCharacter mode="icon" slug="haru" enablePopover />
          <EversoulCharacter mode="icon" slug="melfice" enablePopover />
          <EversoulCharacter mode="icon" slug="bryce" enablePopover />
          <EversoulCharacter mode="icon" slug="aki" enablePopover />
          <EversoulCharacter mode="icon" slug="eileen" enablePopover />
        </div>
        <p>
          Unsurprisingly, one of the most important mechanics in this fight is
          controlling Gaia’s mana - so here are some great options for it!
          (You’ll probably need at least 2 mana controllers on each team)
        </p>
        <ul>
          <li>
            <strong>Eve</strong> - A huge boon to your team, she will
            significantly buff your carry’s attack and feed them mana that she
            drains from Gaia.{' '}
          </li>
          <li>
            <strong>Aira</strong> - Similar to Haru, except provides damage
            boosts through defensive debuffs on the enemy while reducing mana
            generation. Also doubles as a great HP tank for Karma Shackles,
            making her doubly useful!
          </li>
          <li>
            <strong>Ayame</strong> - On top of mana control, she also provides
            an attack amplification for your team, and her DPS is also notable
            in itself.
          </li>
          <li>
            <strong>Haru</strong> - Has been a raid constant for some time due
            to her teamwide attack buff and ability to reduce the boss’ mana
            generation.
          </li>
          <li>
            <strong>Melfice (+ Bryce)</strong> - The dynamic duo works quite
            well in Gaia, especially since Melfice obtained the ability to drain
            mana on her main skill when paired with Bryce.
          </li>
          <li>
            <strong>Aki</strong> - A raid DPS staple since her release, she’s
            also a great mana controller and works spectacularly on a
            human-based team.{' '}
          </li>
          <li>
            <strong>Eileen</strong> - Another great option for mana control, she
            provides a decent amount of damage as a sub-DPS.
          </li>
        </ul>
        <h5>Shackles Tanks</h5>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="daphne" enablePopover />
          <EversoulCharacter mode="icon" slug="lute" enablePopover />
          <EversoulCharacter mode="icon" slug="aira" enablePopover />
          <EversoulCharacter mode="icon" slug="petra" enablePopover />
        </div>
        <p>
          As mentioned before, the unit you want to tank the Karma Shackles
          ability will have high HP (in order to take the aggro) and low attack,
          so that the damage doesn’t kill them.{' '}
        </p>
        <ul>
          <li>
            <strong>Daphne</strong> - Yet again appearing as a top unit, Daphne
            can provide teamwide Atk buffs while sharing the damage taken by
            your other units. DPS boost and survivability!
          </li>
          <li>
            <strong>Lute</strong> - A great raid option for her incredible DPS,
            she serves a double purpose in Gaia. Since her attack damage is
            based on her Defense stat, Shackles won’t do much to her and she can
            still pack a punch!
          </li>
          <li>
            <strong>Aira</strong> - Providing both mana control and a target for
            Shackles, Aira’s double duty makes her an amazing option to slot in
            your team!
          </li>
          <li>
            <strong>Petra</strong> - Fitting very well into the usual Undead
            team, Petra provides shields to help her team survive while she acts
            as the stun target. She also has a significant amount of
            self-sustain, so performs well in this role, even allowing the
            Undead team to fight without a dedicated healer!
          </li>
        </ul>
        <h5>Burners</h5>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="naomi" enablePopover />
          <EversoulCharacter mode="icon" slug="flynn" enablePopover />
          <EversoulCharacter mode="icon" slug="cherrie" enablePopover />
          <EversoulCharacter mode="icon" slug="nini" enablePopover />
        </div>
        <p>
          While not entirely necessary in this raid (as in, the mechanic won’t
          cause your demise if you ignore it), bringing characters that apply
          Burning can raise your DPS, though the debuff applied is only useful
          to characters that deal physical damage.
        </p>
        <ul>
          <li>
            <strong>Naomi</strong> - While Naomi herself doesn’t deal Physical
            damage, she is often paired with Aki, who will greatly appreciate
            the damage increase! She’s also one of the top DPS options for Gaia,
            so honestly the burn is just an added bonus.{' '}
          </li>
          <li>
            <strong>Flynn</strong> - Being a Beast faction unit, she does great
            damage in this raid - however she’s best paired with someone else
            who can take advantage of the debuff, since she’s more of a sub-DPS.{' '}
          </li>
          <li>
            <strong>Cherrie</strong> - Used to be one of the best options for
            Gaia as one of very few units that burns - however she’s become less
            of a staple and more of a filler unit with the release of other
            units.{' '}
          </li>
          <li>
            <strong>Nini</strong> - Like Naomi, Nini’s damage is Magic, so she
            can’t take advantage of her own burn. However, she is still a decent
            option for both Physical and Magical teams, as she also provides a
            35% Magic Resistance debuff and decent damage.
          </li>
        </ul>
        <h5>Main DPS</h5>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="tasha" enablePopover />
          <EversoulCharacter mode="icon" slug="naomi" enablePopover />
          <EversoulCharacter mode="icon" slug="lizelotte" enablePopover />
          <EversoulCharacter mode="icon" slug="eve" enablePopover />
          <EversoulCharacter mode="icon" slug="bryce" enablePopover />
        </div>
        <ul>
          <li>
            <strong>Tasha</strong> - A new contender has entered the arena, and
            her ability to dish out huge DPS to Gaia is certainly incredible.
            She requires a heavily melee-centric team (3+ melee units) to
            perform well, but with units like Haru and Aira and Lute also being
            strong for Gaia, this is barely a noticeable limitation.{' '}
          </li>
          <li>
            <strong>Naomi</strong> - Once again, Naomi’s huge damage potential
            wins her a spot as one of the top DPS options in Gaia. Teaming her
            up with either Jiho or Beleth as a support to spam her main to get
            her stacks up allows her to unleash an ungodly nuke at the end of
            the match!
          </li>
          <li>
            <strong>Lizelotte</strong> - While she has been in slight decline in
            raid DPS ranking as a main carry, she is still a godsend for most
            players who have her, as her DPS is always sufficient, if not the
            best. Against Gaia all her skills are guaranteed crits, and at
            Origin artifact, she also provides mana control. Even if not being
            used as a main DPS, she can be slotted into a team as a very strong
            sub-DPS!
          </li>
          <li>
            <strong>Eve</strong> - If you are able to bump her attack enough to
            be the highest on the team, Eve is able to do a significant amount
            of damage in Gaia through the spamming of her Ultimate. Adding up
            stacks of her “not-quite-DoT” (since it has unlimited duration and
            can crit!) throughout the fight does a surprising amount of damage.
          </li>
          <li>
            <strong>Bryce</strong> - A common sight in raids, the pair of Undead
            twins together allows Bryce to spam her Ultimate with a
            significantly reduced cooldown. With no limitation on Ultimate use
            (such as seen in Rudra), this has great damage potential.
          </li>
        </ul>
        <h5>Supports</h5>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="yuria" enablePopover />
          <EversoulCharacter mode="icon" slug="beleth" enablePopover />
          <EversoulCharacter mode="icon" slug="jiho" enablePopover />
          <EversoulCharacter mode="icon" slug="garnet" enablePopover />
          <EversoulCharacter mode="icon" slug="ayame" enablePopover />
          <EversoulCharacter mode="icon" slug="talia" enablePopover />
          <EversoulCharacter mode="icon" slug="vivienne" enablePopover />
        </div>
        <ul>
          <li>
            <strong>Yuria</strong> - No real explanation needed here. Simply
            apply Yuria to team, and watch it deal significantly increased
            damage.
          </li>
          <li>
            <strong>Beleth</strong> - A very welcome addition to the world of
            Supports! Beleth provides her team with a plethora of amazing DPS
            buffs, great heals, and if using her on a team where she’s the one
            Ulting, mana too!
          </li>
          <li>
            <strong>Jiho</strong> - Still a great option for the Naomi-centric
            team (if Beleth isn’t available), she allows her team to spam their
            skills via the speed boosts she provides with her Ultimate.{' '}
          </li>
          <li>
            <strong>Garnet</strong> - The uncontended master of debuffs,
            Garnet’s abilities allow her team to deal significantly more damage,
            and provides survivability through lifesteal as well!
          </li>
          <li>
            <strong>Ayame</strong> - Performing a double duty in this raid,
            Ayame is able to provide a decent teamwide DPS increase while also
            controlling Gaia’s mana. Her DPS is pretty great too!
          </li>
          <li>
            <strong>Talia</strong> - Still a great option as a team filler due
            to her mana generation, she’s not as sought-after a teammate as she
            once was. But if you need a filler unit, she’s a great option!
          </li>
          <li>
            <strong>Vivi</strong> - Similar to Talia, Vivi’s usefulness has
            diminished slightly over time, however her teamwide speed buff still
            keeps her in the running as a team filling option.
          </li>
        </ul>
        <SectionHeader title="Team examples" />
        <p>
          The following teams are just some examples that can be used to create
          your own teams. They are not completely optimized meta teams, as those
          are usually kept within guilds to compete for rank, but they will
          suffice as a base. Just remember if substituting any of the units
          listed for the mechanics that you’ll have to provide a suitable
          replacement for that mechanic. Some more common substitutions will
          also be listed.
        </p>
        <StaticImage
          src="../../../images/eversoul/gaia/gaia_09.webp"
          alt="Gaia"
        />
        <h5>Human Spam Attack</h5>
        <p>
          Spam Beleth/Jiho’s ultimate the whole fight to make your team extra
          spammy and build up Naomi’s stacks, then unleash the NaomiNuke as your
          last ultimate of the fight and watch the numbers go up!
        </p>
        <ul>
          <li>Mana control: Lize/Aki (requires Lize Origin artifact)</li>
          <li>Shackles tank: Daphne</li>
          <li>Substitutions: </li>
          <ul>
            <li>Beleth &gt; Jiho</li>
            <li>Lize &gt; Eve/Ayame</li>
          </ul>
        </ul>
        <StaticImage
          src="../../../images/eversoul/gaia/gaia_010.webp"
          alt="Gaia"
        />
        <h5>Beast Boost</h5>
        <p>
          With Tasha’s boosts from melee units, and Beast units being mostly
          melee, it becomes decently easy to put together a Beast-centric team
          that deals significant damage, especially if you add Yuria into the
          mix with her faction damage bonus!
        </p>
        <ul>
          <li>Mana control: Haru/Aira</li>
          <li>Shackles tank: Lute/Aira</li>
        </ul>
        <StaticImage
          src="../../../images/eversoul/gaia/gaia_011.webp"
          alt="Gaia"
        />
        <h5>Twins Ultimate Spamming Party</h5>
        <p>
          Between Melfice’s mana drain and Bryce’s guaranteed crits, just add
          some more mana control and a tank, Garnet for good measure, and you’re
          set! If you find yourself dying without a healer, replacing Garnet is
          also an option, as long as you make sure to use her great debuffing
          prowess on one of your other teams.
        </p>
        <ul>
          <li>Mana control: Melfice/Ayame</li>
          <li>Shackles tank: Petra</li>
        </ul>
        <StaticImage
          src="../../../images/eversoul/gaia/gaia_012.webp"
          alt="Gaia"
        />
        <h5>Eve Doesn’t Want to Support</h5>
        <p>
          If you can pull it off, making Eve your main DPS is a completely
          viable option! It requires your teammates to have lower attack than
          her, which involves a lot of investment on Eve so this team is not for
          everyone. It is somewhat easy to use, however, since the damage is not
          spiky and applied over time, it makes mana control easier to handle.
        </p>
        <ul>
          <li>Mana control: Eve</li>
          <li>Shackles tank: Daphne</li>
        </ul>
        <StaticImage
          src="../../../images/eversoul/gaia/gaia_013.webp"
          alt="Gaia"
        />
        <h5>Another Lize Team:</h5>
        <p>
          If you’re lacking in some of the other main DPS options, Lize is
          always a great choice. She also provides Flynn with the opportunity to
          come out and play, as her burn will help raise Lize’s damage as well!
          If Lize’s artifact is Origin, the extra mana control unit can be
          swapped out for something else.
        </p>
        <ul>
          <li>Mana control: Aira/Eileen/Lize</li>
          <li>Shackles tank: Aira</li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default EsGuidesGaia;

export const Head: React.FC = () => (
  <Seo
    title="Gaia - raid guide | Eversoul | Prydwen Institute"
    description="Struggling with the Gaia Raid? This guide will help you!"
    game="eversoul"
  />
);
